import './imageCropper.less';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '../shared/button/Button';
import PropTypes from 'prop-types';
import React from 'react';
import ReactCrop, { getPixelCrop, makeAspectCrop } from 'react-image-crop';

const propTypes = {
  aspectRatio: PropTypes.number,
  base64Image: PropTypes.string.isRequired,
  defaultHeight: PropTypes.number,
  defaultWidth: PropTypes.number,
  handleCancelClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const defaultProps = {
  aspectRatio: 0,
  defaultHeight: 60,
  defaultWidth: 60,
};

const getCroppedImgBlob = async (image, pixelCrop, callback) => {
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  // allow cross origin since image is coming from our CDN
  image.crossOrigin = 'anonymous';
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // inefficient polyfill for browsers without canvas.toBlob
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value(blobCallback, type, quality) {
        const canvasPrototype = this;
        setTimeout(() => {
          const binStr = atob(canvasPrototype.toDataURL(type, quality).split(',')[1]);
          const len = binStr.length;
          const arr = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }
          blobCallback(new Blob([arr], { type: type || 'image/png' }));
        });
      },
    });
  }

  // As a blob
  canvas.toBlob((blob) => {
    callback(blob, image.src, pixelCrop);
  });
};

class ImageCropper extends React.Component {
  state = {
    crop: {},
    // they have to do something with the cropper before they can save
    disableSave: false,
    image: null,
  };

  onChange = (crop, pixelCrop) => {
    this.setState({ crop, disableSave: false, pixelCrop });
  };

  onSaveClick = () => {
    const { handleSaveClick } = this.props;
    const { image, pixelCrop } = this.state;

    const callback = (blob, originalSrc, crop) => {
      const fileObj = {};
      fileObj.type = blob.type;
      fileObj.val = blob;
      fileObj.crop = crop;
      fileObj.originalSrc = originalSrc;

      handleSaveClick(fileObj);
    };

    getCroppedImgBlob(image, pixelCrop, callback);
    this.setState({ disableSave: true });
  };

  onImageLoaded = (image) => {
    const { aspectRatio, defaultHeight, defaultWidth } = this.props;

    let crop = {
      height: defaultHeight,
      width: defaultWidth,
      x: 0,
      y: 0,
    };

    if (aspectRatio) {
      crop = makeAspectCrop(
        {
          aspect: aspectRatio,
          width: defaultWidth,
          x: 0,
          y: 0,
        },
        image.width / image.height,
      );
    }

    const pixelCrop = getPixelCrop(image, crop);

    this.setState({ crop, image, pixelCrop });
  };

  render() {
    const { id, base64Image, handleCancelClick } = this.props;
    const { crop, disableSave } = this.state;

    return (
      <div className="ufr-image-cropper">
        <div className="ufr-crop-area">
          <ReactCrop
            src={base64Image}
            onChange={this.onChange}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            style={{ backgroundColor: 'transparent' }}
          />
        </div>

        <Button
          id={`${id}-crop-cancel`}
          ufrStyle="secondary"
          label="cancel crop"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          id={`${id}-crop-save`}
          ufrStyle="primary"
          label="save crop"
          onClick={this.onSaveClick}
          disabled={disableSave}
        >
          Save
        </Button>
      </div>
    );
  }
}

ImageCropper.propTypes = propTypes;
ImageCropper.defaultProps = defaultProps;

export default ImageCropper;
